import type { FC, SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { Box, Paper, Tabs } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { useGetAllInputFormsQuery } from "../../store/scope3InputForms/scope3InputForms.api";
import { RouteConfig } from "../../components/App/Routes/routes.config";
import { Autocomplete } from "../../components/common/Autocomplete/Autocomplete.component";
import type { AutocompleteOption } from "../../components/common/Autocomplete/Autocomplete.types";
import { InfoBox } from "../../components/common/InfoBox/InfoBox.component";
import { Loader } from "../../components/common/Loader/Loader.component";
import { TabPanel } from "../../components/common/TabPanel/TabPanel.component";
import { TextFieldSkeleton } from "../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { Scope3FormTab } from "./Scope3FormTab/Scope3FormTab.component";
import { Scope3TableTab } from "./Scope3TableTab/Scope3TableTab.component";
import { parseLocations } from "./ValueChainPage.utils";
import { StyledTab } from "./ValueChainPage.styles";

export const ValueChainPage: FC = () => {
  const { t } = useTranslation()
  const [selectedLocation, setSelectedLocation] = useState<AutocompleteOption<number> | null>(null)
  const [value, setValue] = useState(0);
  const { inputFormId } = useParams()

  const {
    data: locations,
    isLoading: locationsLoading
  } = useGetAllLocationsQuery({ addOptionAll: false })

  const {
    data: inputForms,
    isLoading: inputFormsLoading
  } = useGetAllInputFormsQuery()

  const currentInputForm = inputForms?.find((inputForm) => inputForm.scope3InputFormId === Number(inputFormId))
  const parsedLocations = parseLocations(locations)

  const incorrectInputFormId = 
  inputForms
    && inputForms.length > 0
    && (
      !inputFormId
      || !currentInputForm
    )

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeLocation = async (location: AutocompleteOption<number> | null) => {
    setSelectedLocation(location)
  }
  
  useEffect(() => {
    if (parsedLocations?.length && !locationsLoading) {
      setSelectedLocation(parsedLocations[0])
    }
  }, [locationsLoading])
  
  if (incorrectInputFormId) {
    const path = RouteConfig.VALUE_CHAIN.fullPath.replace(":inputFormId?", inputForms[0]?.scope3InputFormId.toString())

    return <Navigate to={path} replace={true}/>;
  }

  if (locationsLoading || inputFormsLoading) {
    return <Loader />
  }

  return (
    <PageWrapper>
      {currentInputForm?.description ? <InfoBox
        type="info"
        sx={{
          border: "1px solid #759FE1",
          mb: 2,
        }}
      >
        {currentInputForm.description}
      </InfoBox> : null}

      <Paper>
        <Box>
          {parsedLocations ? (
            <Autocomplete
              label={t("valueChainEmissions:locationsSelectLabel")}
              placeholder={t("valueChainEmissions:locationsSelectLabel")}
              options={parsedLocations}
              onChange={async (_, data) => handleChangeLocation(data)}
              value={selectedLocation}
            />
          ) : <TextFieldSkeleton withLabel /> }
        </Box>

        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ mb: 3 }}
        >
          <StyledTab label={t("valueChainEmissions:formTabLabel")} />
          <StyledTab label={t("valueChainEmissions:savedItemsTabLabel")} disabled={!selectedLocation} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Scope3FormTab
            inputFormId={Number(inputFormId)}
            locationId={Number(selectedLocation?.value)}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Scope3TableTab
            inputFormId={Number(inputFormId)}
            locationId={Number(selectedLocation?.value)}
          />
        </TabPanel>
      </Paper>
    </PageWrapper>
  )
}