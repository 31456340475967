import { colors } from "../../../theme/colors";
import { fallbackColorsPrimary } from "../../../utils/fallbackColors";
import type { ElectricityByTypeResponseModel } from "../../../store/widgetContents/widgetContents.types";
import type { EntryValue, LineChartDataModel } from "../../charts/LineChart/LineChart.types";

export const mergeData = (inputData: ElectricityByTypeResponseModel): LineChartDataModel[] => {
  const outputData: LineChartDataModel[] = [];
  const fallbackColors = fallbackColorsPrimary()

  const getUniqueColor = () => {
    if (fallbackColors.length === 0) {
      return colors.chartPrimaryBlue
    }
    const randomIndex = Math.floor(Math.random() * fallbackColors.length);
    const color = fallbackColors[randomIndex];
    fallbackColors.splice(randomIndex, 1);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return color
  };

  inputData.types.forEach((type) => {
    const lineColor = type.colour
      ? type.colour
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      : getUniqueColor()
    
    type.entries.forEach((entry) => {
      const entryValue: EntryValue = {
        color: lineColor,
        name: type.name,
        value: entry.value,
      };

      const existingEntry = outputData.find((data) => data.date === entry.date);

      if (existingEntry) {
        existingEntry.entries[type.name.replace(/ /gu,"")] = entryValue;
      } else {
        const newEntry: LineChartDataModel = {
          date: entry.date,
          entries: { [type.name.replace(/ /gu,"")]: entryValue },
          unit: inputData.unit,
        };

        outputData.push(newEntry);
      }
    });
  });

  return outputData;
}