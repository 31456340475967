import type { FC } from "react";
import { Fragment,useState  } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useAddNewScope3ConsumptionConfigMutation, useUpdateScope3ConsumptionConfigMutation } from "../../../../store/scope3Consumptions/scope3Consumptions.api";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { Toast } from "../../../../components/common/Toast/Toast.component";
import { Scope3CheckboxField } from "./Scope3CheckboxField/Scope3CheckboxField.component";
import { Scope3DatePicker } from "./Scope3DatePicker/Scope3DatePicker.component";
import { Scope3DecimalField } from "./Scope3DecimalField/Scope3DecimalField.component";
import { Scope3DropdownField } from "./Scope3DropdownField/Scope3DropdownField.component";
import { Scope3MethodRadioGroup } from "./Scope3MethodsRadioGroup/Scope3MethodsRadioGroup.component";
import { Scope3TextField } from "./Scope3TextField/Scope3TextField.component";
import { getDefaultValues, getInitialValues, getPayload } from "./Scope3Form.utils";
import type { Scope3FieldExtendedResponseModel } from "../../../../store/scope3Fields/scope3Fields.types";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import type { Scope3FormProps } from "./Scope3Form.types";
import { InputFormFieldType } from "./Scope3Form.types";

import { createValidationSchema } from "./Scope3Form.validation";

export const Scope3Form: FC<Scope3FormProps> = ({ methods, inputFormId, inputFormFields, locationId, consumption }) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [addNewScope3Consumption, { isLoading: addConsumptionLoading }] = useAddNewScope3ConsumptionConfigMutation()
  const [updateNewScope3Consumption, { isLoading: updateConsumptionLoading }] = useUpdateScope3ConsumptionConfigMutation()

  const scope3ConsumptionEntryId = consumption?.find(item => item.name === "scope3ConsumptionEntryId")?.id;
  const scope3MethodId: number | undefined = consumption?.find(item => item.name === "scope3MethodId")?.id;

  const fieldNames = new Set(inputFormFields.map(field => field.name));
  const filteredData = consumption?.filter(item => fieldNames.has(item.name));

  const form = useForm({
    defaultValues: {
      scope3MethodId: scope3MethodId?.toString() ?? methods[0]?.scope3MethodId?.toString(),
      ...(consumption ? getDefaultValues(filteredData, inputFormFields) : getInitialValues(inputFormFields)),
    },
    mode: "onSubmit",
    resolver: useYupResolver(() => createValidationSchema(t, inputFormFields)),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload = getPayload(values, inputFormId, locationId)

    try {
      await addNewScope3Consumption(payload).unwrap()
      setSuccess(true)
      form.reset()
    } catch(e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name , {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
      setIsError(true)
    }
  })

  const handleUpdate = form.handleSubmit(async (values) => {
    const payload = getPayload(values, undefined, undefined, scope3ConsumptionEntryId)
    try {
      await updateNewScope3Consumption(payload).unwrap()
      setSuccess(true)
      form.reset()
    } catch(e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      } else {
        setIsError(true)
      }
    }
  })

  const createField = (formField: Scope3FieldExtendedResponseModel) => {
    switch(formField.fieldType) {
      case InputFormFieldType.DROPDOWN:
        return (
          <Scope3DropdownField
            field={formField}
            inputFormId={inputFormId}
            disabled={!locationId}
          />
        )
      case InputFormFieldType.TEXT_FIELD:
        return (
          <Grid item xs={3} minWidth="235px">
            <Scope3TextField
              field={formField}
              disabled={!locationId}
            />
          </Grid>
        )
      case InputFormFieldType.DECIMAL:
        return (
          <Grid item xs={3} minWidth="235px">
            <Scope3DecimalField
              field={formField}
              inputFormId={inputFormId}
              disabled={!locationId}
            />
          </Grid>
        )
      case InputFormFieldType.DATE_PICKER:
        return (
          <Grid item xs={3} minWidth="235px">
            <Scope3DatePicker
              field={formField}
              disabled={!locationId}
            />
          </Grid>
        )
      case InputFormFieldType.CHECKBOX:
        return (
          <Grid
            item xs={3}
            minWidth="235px"
            display="flex"
            alignItems="flex-end"
          >
            <Scope3CheckboxField
              field={formField}
              disabled={!locationId}
            />
          </Grid>
        )
      default:
        break
    }
  }

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={consumption ? handleUpdate : handleSubmit}
        bgcolor="grey.200"
        p={1.5}
        borderRadius="4px"
      >
        <Scope3MethodRadioGroup
          methods={methods}
          disabled={!locationId}
        />

        <Grid container spacing={2}>
          {inputFormFields.map((inputFormField) => (
            <Fragment key={inputFormField.scope3FieldId}>
              {createField(inputFormField)}
            </Fragment>
          ))}
        </Grid>

        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            type="submit"
            size="small"
            disabled={!locationId || addConsumptionLoading || updateConsumptionLoading}
            endIcon={addConsumptionLoading || updateConsumptionLoading ? <ButtonLoader /> : null}
          >
            {t("valueChainEmissions:submitButton")}
          </Button>
        </Box>
      </Box>

      <Toast
        open={success}
        title={t("valueChainEmissions:dataSaved")}
        severity="success"
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      />

      <Toast
        open={isError}
        title={t("errors:globalError")}
        severity="error"
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      />
    </FormProvider>
  )
}