import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { deleteFuelConsumption } from "../consumptions/consumptions.slice";
import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type {
  ConsumptionByFuelResponseModel,
  FuelConsumptionResponseModel,
  MinMaxDatesModel,
  PostFuelConsumptionPayloadType,
  UpdateFuelConsumptionPayloadType
} from "./fuelConsumptions.types";

import { deleteFuelConsumptionConfig, getFuelsConsumptionsByIdConfig, getFuelsConsumptionsConfig, getFuelsConsumptionsMinMaxDatesConfig, postFuelConsumptionConfig, updateFuelConsumptionConfig } from "./fuelConsumptions.config";

export const FuelConsumptionsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetFuelAggregates", "widgetFuelConsumptions"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getFuelsConsumptionsMinMaxDates: build.query<MinMaxDatesModel[], { locationId: number, fuelId: number }>({
        query: ({ locationId, fuelId }) => ({
          ...getFuelsConsumptionsMinMaxDatesConfig(locationId, fuelId),
        }),
        providesTags: ["widgetFuelConsumptions"]
      }),
      getFuelsConsumptions: build.query<FuelConsumptionResponseModel[], { locationId: number, pageNumber?: number }>({
        query: ({ locationId, pageNumber }) => ({
          ...getFuelsConsumptionsConfig(locationId),
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: FuelConsumptionResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["widgetFuelConsumptions"]
      }),
      postFuelConsumption: build.mutation<FuelConsumptionResponseModel, PostFuelConsumptionPayloadType>({
        query: (payload) => ({
          ...postFuelConsumptionConfig,
          data: payload
        }),
        invalidatesTags: ["widgetFuelAggregates", "widgetFuelConsumptions"]
      }),
      updateFuelConsumption: build.mutation<FuelConsumptionResponseModel, UpdateFuelConsumptionPayloadType>({
        query: ({ consumptionId, payload }) => ({
          ...updateFuelConsumptionConfig(consumptionId),
          data: {
            fuelConsumptionId: consumptionId,
            ...payload
          }
        }),
        invalidatesTags: (result, error) => error ? [] : ["widgetFuelAggregates", "widgetFuelConsumptions"]
      }),
      deleteFuelConsumption: build.mutation<void, { consumptionId: number }>({
        query: ({ consumptionId }) => ({
          ...deleteFuelConsumptionConfig(consumptionId),
        }),
        onQueryStarted: async ({ consumptionId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled

            dispatch(deleteFuelConsumption(consumptionId))
          } catch (error) {}
        },
        invalidatesTags: (result, error) => error ? [] : ["widgetFuelAggregates", "widgetFuelConsumptions"]
      }),
      getFuelsConsumptionsById: build.query<
      ConsumptionByFuelResponseModel[],
      {
        locationId: number,
        fuelId: number,
        startDate: string,
        endDate: string
      }>({
        query: ({ locationId, fuelId, startDate, endDate }) => ({
          ...getFuelsConsumptionsByIdConfig(locationId, fuelId),
          params: {
            startDate,
            endDate
          }
        }),
      }),
    }),
  });

export const {
  useLazyGetFuelsConsumptionsMinMaxDatesQuery,
  useGetFuelsConsumptionsQuery,
  useLazyGetFuelsConsumptionsQuery,
  usePostFuelConsumptionMutation,
  useDeleteFuelConsumptionMutation,
  useUpdateFuelConsumptionMutation,
  useLazyGetFuelsConsumptionsByIdQuery,
} = FuelConsumptionsApi