import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { deleteRenewableConsumption } from "../consumptions/consumptions.slice";
import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type {
  ConsumptionByRenewableResponseModel,
  RenewableConsumptionPostModel,
  RenewableConsumptionPutModel,
  RenewableConsumptionResponseModel
} from "./renewableConsumptions.types";

import { deleteRenewableConsumptionConfig, getRenewableConsumptionsByIdConfig, getRenewableConsumptionsConfig, postRenewableConsumptionConfig, updateRenewableConsumptionConfig } from "./renewableConsumptions.config";

export const RenewableConsumptionsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetRenewablesConsumptions"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getRenewablesConsumptions: build.query<RenewableConsumptionResponseModel[], { locationId: number, pageNumber?: number }>({
        query: ({ locationId, pageNumber }) => ({
          ...getRenewableConsumptionsConfig(locationId),
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: RenewableConsumptionResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["widgetRenewablesConsumptions"]
      }),
      postRenewableConsumption: build.mutation<RenewableConsumptionResponseModel, RenewableConsumptionPostModel>({
        query: (payload) => ({
          ...postRenewableConsumptionConfig,
          data: payload
        }),
        invalidatesTags: ["widgetRenewablesConsumptions"]
      }),
      updateRenewableConsumption: build.mutation<RenewableConsumptionResponseModel, RenewableConsumptionPutModel>({
        query: (payload) => ({
          ...updateRenewableConsumptionConfig(payload.renewableConsumptionId),
          data: {
            ...payload
          }
        }),
        invalidatesTags: (result, error) => error ? [] : ["widgetRenewablesConsumptions"]
      }),
      deleteRenewableConsumption: build.mutation<void, { consumptionId: number }>({
        query: ({ consumptionId }) => ({
          ...deleteRenewableConsumptionConfig(consumptionId),
        }),
        onQueryStarted: async ({ consumptionId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled

            dispatch(deleteRenewableConsumption(consumptionId))
          } catch (error) {}
        },
        invalidatesTags: (result, error) => error ? [] : ["widgetRenewablesConsumptions"]
      }),
      getRenewableConsumptionsById: build.query<
      ConsumptionByRenewableResponseModel[],
      {
        locationId: number,
        renewableId: number,
        startDate: string,
        endDate: string
      }>({
        query: ({ locationId, renewableId, startDate, endDate }) => ({
          ...getRenewableConsumptionsByIdConfig(locationId, renewableId),
          params: {
            startDate,
            endDate
          }
        }),
      }),
    }),
  });

export const {
  useGetRenewablesConsumptionsQuery,
  useLazyGetRenewablesConsumptionsQuery,
  usePostRenewableConsumptionMutation,
  useUpdateRenewableConsumptionMutation,
  useDeleteRenewableConsumptionMutation,
  useLazyGetRenewableConsumptionsByIdQuery,
} = RenewableConsumptionsApi