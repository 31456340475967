/* eslint-disable @typescript-eslint/no-unsafe-return */
import { theme } from "../theme/theme";

type ChartPalettePrimaryKeys = "primaryBlue" | "primaryGreen" | "primaryGrey1" | "primaryGrey2" | "primaryTurquoise" | "primaryYellow"
type ChartPaletteSecondaryKeys = "secondaryBlue" | "secondaryGreen" | "secondaryGrey1" | "secondaryGrey2" | "secondaryTurquoise" | "secondaryYellow"

export const fallbackColorsPrimary = ():string[] => (Object.keys(theme.palette.chart) as ChartPalettePrimaryKeys[])
  .filter(key => key.includes("primary"))
  .map(key => theme.palette.chart[key]);

export const fallbackColorsSecondary = ():string[] => (Object.keys(theme.palette.chart) as ChartPaletteSecondaryKeys[])
  .filter(key => key.includes("secondary"))
  .map(key => theme.palette.chart[key]);